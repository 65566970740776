<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <div class="wbg">
          <h1>¡Consigue efectivo al </h1>
          <h1>instante con BilletePlus!  </h1>
          <h4>En 4 Pasos Sencillos, Obtenga Su Dinero En Un</h4>
          <h4>Plazo De 2 A 24 Horas.</h4>
        </div>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div id="selector1" class="inner inner2">
      <h3>Disfrute de la experiencia del Préstamo Premium</h3>
      <div class="inner2_item flex fw-w">
        <div v-for="(item,index) in leftItemList" :key="index" class="content">
          <!-- <img class="bg" :src="item.img" alt=""> -->
          <img class="bg2" :src="item.img2" alt="">
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
        </div>
      </div>
    </div>
    <div id="selector2" class="inner inner3">
      <div class="left_item">
        <img src="@/assets/images/iphone.png" alt="">
      </div>
      <div class="right_item">
        <h1>Reciba su efectivo rápidamente <br> en 5 pasos</h1>
        <div class="item_info">
          <div class="info_tips">
            <div class="items">
              <img class="info_img1" src="@/assets/images/loanImg/app-icon-1.png" alt="">
              <div>
                <h3>Descarga la App</h3>
                <p>Vaya a GooglePlay para descargar BilletePlus</p>
              </div>
            </div>
            <div class="items">
              <img class="info_img1" src="@/assets/images/loanImg/app-icon-2.png" alt="">
              <div>
                <h3>Registrarse y verificar</h3>
                <p>Debe registrarse y completar la información requerida para terminar la verificación.</p>
              </div>
            </div>
            <div class="items">
              <img class="info_img1" src="@/assets/images/loanImg/app-icon-3.png" alt="">
              <div>
                <h3>Completar la solicitud</h3>
                <p>Tómese unos minutos para proporcionar los conceptos básicos y evaluaremos el monto del préstamo que podemos ofrecerle</p>
              </div>
            </div>
            <div class="items">
              <img class="info_img1" src="@/assets/images/loanImg/app-icon-4.png" alt="">
              <div>
                <h3>Recibe tu dinero en efectivo</h3>
                <p>Download the app and complete the registration</p>
              </div>
            </div>
            <div class="items">
              <img class="info_img1" src="@/assets/images/loanImg/app-icon-5.png" alt="">
              <div>
                <h3>Registered</h3>
                <p>Download the app and complete the registration</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="selector3" class="inner inner4">
      <h3>Comentarios de los usuarios</h3>
      <p><img src="@/assets/images/loanImg/star2.png">4.5</p>
      <div class="flex al-c jc-c">
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in visibleItems" :key="item.id" class="carousel-item flex fd-c">
                <img class="tx" :src="item.img">
                <div>
                  <h1>{{ item.name }}</h1>
                  <h2>{{ item.txt }}</h2>
                  <p>{{ item.txt2 }}</p>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <div class="flex jc-c">
        <button class="carousel-button" :disabled="currentIndex === 0" @click="previousSlide"><img src="@/assets/images/loanImg/black_left.png"></button>
        <button class="carousel-button" :disabled="currentIndex >= items.length - 3" @click="nextSlide"><img src="@/assets/images/loanImg/black_right.png"></button>
      </div>
    </div>
    <div id="selector4" class="inner inner5">
      <!-- <h4>Ventajas</h4> -->
      <div class="content">
        <h1>Perfil de la empresa</h1>
        <div class="left_cell">
          <p class="inner1_text">BilletePlus es una plataforma que ofrece préstamos en línea rápidos, accesibles y seguros. Regístrate en segundos,<br>
            aplica para un préstamo y recibe tu efectivo en minutos. Vemos el mundo diferente. Estamos motivados por una<br>
            creencia fundamental en las personas, y trabajamos juntos para demostrar su potencial. Asumimos riesgos que<br>
            otros no tomarían para poner más poder en las manos de nuestros clientes.
          </p>
        </div>
      </div>
    </div>
    <div id="selector5" class="inner inner6">
      <div class="inner6_item">
        <div class="inner6_item_left">
          <div>
            <h1 class="inner6_item_left_title">How To Contact Us</h1>
            <div v-for="(item,index) in inner6ItemLeftList" :key="index" class="inner6_item_left_list ">
              <img :src="item.img">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
        <div class="inner6_item_right"><img src="@/assets/images/kefu.png" alt=""></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftItemList: [
        { txt: 'Préstamos rápidos y fáciles', txt2: 'Los préstamos de BilletePlus son \n rápidos y fáciles de obtener. Los clientes \n pueden solicitar préstamos en línea en \n cualquier momento y desde cualquier \n lugar. El proceso de aprobación es rápido \n y los clientes reciben sus préstamos \n inmediatamente.', img: require('@/assets/images/itemList_01.png'), img2: require('@/assets/images/itemList_04.png') },
        { txt: 'Flexibilidad de pagos', txt2: 'BilletePlus ofrece una amplia variedad \n de opciones de pago para adaptarse a \n las necesidades de cada cliente. Los \n clientes pueden elegir el plazo de su \n préstamo y el método de pago que \n mejor les convenga.', img: require('@/assets/images/itemList_02.png'), img2: require('@/assets/images/itemList_05.png') },
        { txt: 'Sin garantías ni avales', txt2: 'BilletePlus no requiere garantías ni \n avales para otorgar préstamos, lo que \n hace que sus servicios sean accesibles \n para una amplia gama de personas.', img: require('@/assets/images/itemList_03.png'), img2: require('@/assets/images/itemList_06.png') },
        { txt: 'Tasas de interés competitivas', txt2: 'Las tasas de interés de BilletePlus \n son competitivas en comparación \n con otros prestamistas en línea. \n Esto significa que los clientes \n pueden obtener préstamos a precios \n razonables.', img: require('@/assets/images/itemList_04.png'), img2: require('@/assets/images/itemList_06.png') },
        { txt: 'Servicio al cliente amigable', txt2: 'BilletePlus se esfuerza por brindar \n un excelente servicio al cliente. Su \n equipo está disponible para responder \n preguntas y ayudar a los clientes en \n cada paso del proceso de solicitud y \n pago del préstamo.', img: require('@/assets/images/itemList_05.png'), img2: require('@/assets/images/itemList_06.png') }
      ],
      leftItemList2: [
        { title: 'What can you get?', list2: [{ img: require('@/assets/images/list01.png'), txt: 'From 1,000 ₱ up to 50,000 ₱' }, { img: require('@/assets/images/list02.png'), txt: 'Repayment Term：0-180 days' }, { img: require('@/assets/images/list03.png'), txt: 'Annual interest rate：10%-35%' }] },
        { title: 'Who is eligible to get a loan?', list2: [{ img: require('@/assets/images/list04.png'), txt: 'Age 18-60 years' }, { img: require('@/assets/images/list05.png'), txt: 'Philippines citizen' }, { img: require('@/assets/images/list06.png'), txt: 'Employed or Business-owner' }] }
      ],
      inner5ItemLeftList: [
        { title: 'Online service', txt: 'You can find online services in ', txt2: 'the BilletePlus app.', img: require('@/assets/images/ng_1.png') },
        { title: 'E-mail', txt: 'tigercreditapp@gmail.com', img: require('@/assets/images/ng_2.png') },
        { title: 'Customer service hours', txt: '9AM-6PM', img: require('@/assets/images/ng_3.png') }
      ],
      items: [
        // 您的轮播内容数据
        { id: '1', name: 'Omar', txt: 'Líder de línea', txt2: 'De las mejores aplicaciones de préstamo que e \n visto, puedes tener más de un préstamo simpre \n llegará rápido el efectivo.', img: require('@/assets/images/Product01.png') },
        { id: '2', name: 'Ladyce', txt: 'Asistente', txt2: 'Me agrada la forma de solicitar la información \n tengo la certeza que protegerán mis datos con \n el aviso de privacidad En espera de recibir el \n préstamo … Gracias por su confianza Recibido \n el préstamo.', img: require('@/assets/images/Product02.png') },
        { id: '3', name: 'Arii', txt: 'Repartidor', txt2: 'Mi primer experiencia, una aplicacion que te da \n la oportunidad de pagar en parcialidades.. \n Excelente la recomiendo.', img: require('@/assets/images/Product03.png') }
      ],
      visibleItemCount: 3,
      currentIndex: 0,
      inner_4items: [
        // 您的轮播内容数据
        { id: '1', name: 'Lando Wolf', txt: 'Descarga la App', txt2: 'Vaya a GooglePlay para descargar \n BilletePlus ', img: require('@/assets/images/icon-1.png') },
        { id: '2', name: 'Alice Ura', txt: 'Registrarse y verificar', txt2: 'Debe registrarse y completar la \n información requerida para terminar \n la verificación.', img: require('@/assets/images/icon-2.png') },
        { id: '3', name: 'Marino Zavaleta', txt: 'Solicitar préstamos', txt2: 'Puede elegir el monto de préstamos \n de acuerdo con sus necesidades y \n agregar una cuenta para recibir el \n dinero.', img: require('@/assets/images/icon-3.png') },
        { id: '4', name: 'Obtención de \n préstamos', txt: 'Obtención de \n préstamos', txt2: 'Una vez aprobado el sistema, el dinero \n se transferirá directamente a su tarjeta \n bancaria.', img: require('@/assets/images/icon-4.png') },
        { id: '5', name: 'Alhertina Rayon', txt: 'Pagar el préstamo a \n tiempo', txt2: 'Para aumentar el límite de crédito y \n evitar multas, debe pagar a tiempo \n los préstamos antes de las fechas \n de vencimiento.', img: require('@/assets/images/icon-5.png') },
        { id: '6', name: 'Alhertina Rayon', txt: 'Volver a pedier \n préstamos', txt2: 'Nos complace ayudarlo a alcanzar \n la vida de su sueño y esperamos \n poder brindarle ayuda nuevamente.', img: require('@/assets/images/icon-5.png') }
      ],
      inner_3items: [
        { img: require('@/assets/images/inner_4_1.png'), title: 'Monto alto, \n desembolso rápido', list2: [{ txt: 'Puede elegir el monto y el plazo libremente. \n El monto del préstamo es de hasta $50,000. \n Se transferirá directamente a la cuenta bancaria \n y el préstamo se liberará en tan solo 5 minutos.' }, { txt: '' }, { txt: '' }] },
        { img: require('@/assets/images/inner_4_2.png'), title: 'Bajo interés, seguro \n y confiable', list2: [{ txt: 'El interés del préstamo es muy competitivo y o \n frece ofertas especiales. Los procesos son \n simples y seguros.' }, { txt: '' }] },
        { img: require('@/assets/images/inner_4_3.png'), title: 'Fácil acceso a los \n préstamos', list2: [{ txt2: 'Solo necesitas enviar una solicitud, te atenderemos \n durante todo el proceso. Sin salir de casa, el depósito \n llega en sólo 5 minutos, para que digas adiós a tu crisis \n financiera y empieces a vivir feliz!' }] },
        { img: require('@/assets/images/inner_4_4.png'), title: 'Tu dinero cuando \n quieras', list2: [{ txt: 'Preste dinero todo el día los 365 días, acceso a \n tu salario ya trabajado 24/7. Sin salir de casa, \n sin largas filas, sin papeleo tedioso.' }] }
      ],
      inner6ItemLeftList: [
        { title: 'billeteplus@gmail.com', img: require('@/assets/images/email.png') },
        { title: 'Parque Industrial las Américas 7, Apodaca, NLE, 66645 México', img: require('@/assets/images/address.png') },
        { title: 'De lunes a viernes 9:00-17:00', img: require('@/assets/images/time.png') }
      ]
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  created() {
    // setInterval(() => {
    //   this.nextSlide()
    // }, 4)
  },
  methods: {
    inner_2Class(index) {
      if (index === 4) {
        return 'content2'
      } else if (index % 2 !== 0) {
        return 'text_r'
      }
    },
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=billeteplus.credito.app', '_blank')
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.visibleItemCount) {
        this.currentIndex++
      } else {
        this.currentIndex--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top{
  min-width: 1200px;
  background: #fff;
  white-space: pre-line;
  .inner{
    width: 100%;
    padding:60px calc((100% - 1100px) / 2);
    margin: 0 auto;
  }
  .inner1{
    // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
    padding:60px calc((100% - 1100px) / 2);
    background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat ;
    background-size: 100% 100%;
    // background-position: 85% 50%;
    height: 450px;
    display: flex;
    justify-content: space-between;
    .left_item{
      box-sizing: border-box;
      border-radius: 10px;
      h1{
        font-size: 38px;
        font-weight: 700;
        // margin-bottom: 10px;
        line-height: 70px;
        span{
          color: #00BB53;
        }
      }
      h4{
        font-size: 16px;
        line-height: 30px;
        font-weight: normal;
        // margin: 0px 0;
      }
      & h4:nth-of-type(1){
        margin-top: 10px;
      }
      .item_info{
        margin: 20px 0;
        display: flex;
        height: 170px;
        .info_img1{
          width: 18px;
        }
        .info_tips{
          display: flex;
          flex-direction: column;
          p{
            flex: 1;
            display: flex;
            box-sizing: border-box;
            // margin-left: 10px;
            font-size: 16px;
            color: #FFFFFF;
            align-items: center;
            img{
              margin-right: 10px;
            }
          }
        }
        .info_img2{
          width: 135px;
          height: 135px;
        }
      }
      .item_download{
        // padding-left: 22px;
        cursor: pointer;
        margin-top: 40px;
        color: white;
        border-radius: 5px;
        img{
          width: 190px;
          height: 60px;
          margin-right: 5px;
        }
      }
    }
    .right_item{
      img{
        height: 360px;
        margin-right: 30px;
      }
    }
  }
  .inner2{
    position: relative;
    padding:100px calc((100% - 1100px) / 2) 60px;
    // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    background: #F3F3F3;
    h3{
      font-size: 30px;
      margin-bottom: 80px;
      text-align: center;
    }
    p{
      color: #666666;
      font-size: 14px;
      margin-bottom: 60px;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      // background: #F1F5FF;
      border-radius: 20px;
      width: 33.3%;
      color:black;
      padding: 0 0 30px 0;
      .bg{
        width: 100%;
      }
      .bg2{
        display: block;
        width: 20%;
        margin: 0px auto 0;
      }
      p{
        color: #666666;
        font-size: 13px;
        line-height: 20px;
        font-weight: bold;
        width: 70%;
        margin: 0 auto;
      }
      .item_info{
        h1{
          font-size: 18px;
          line-height: 50px;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
    .inner3{
      padding:80px calc((100% - 1000px) / 2);
      background:#F8F9FF;
      display: flex;
      justify-content: space-between;
      .left_item{
        flex: 1;
        img{
          width: 300px;
          height: 570px;
        }
      }
      .right_item{
        flex: 1.2;
        margin-right:30px;
        h1{
          font-size: 25px;
          line-height: 47px;
          margin-top: 20px;
        }
        p{
          color:#2665D9 ;
          font-size: 20px;
          font-weight: bold;
        }
        .item_tips{
          display: flex;
          width: 460px;
          justify-content: space-between;
          p{
            width: 70px;
            font-size: 12px;
            color: #333333;
            text-align: center;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          .info_img1{
            width: 30px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .items{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              padding: 10px 60px 10px 0px;
              border-radius: 10px;
              align-items: center;
              margin-bottom: 10px;
              img{
                margin-right: 20px;
              }
            }
            .items:nth-last-child(1){
              margin-bottom: 0px !important;
            }
            p{
              font-size: 12px;
              color: #666666;
              line-height: 20px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  .inner4{
    background: #F3F3F3;
    padding: 50px calc((100% - 1050px) / 2) 60px;
    h3{
      font-size: 30px;
      text-align: center;
      // margin-bottom: 20px;
      position: relative;
      margin-bottom: 30px;
      // &::after{
      //   display: block;
      //   content:'';
      //   width: 80px;
      //   height: 5px;
      //   background: #00BB53;
      //   margin: 20px auto 0;
      // }
    }
    p{
      font-size: 22px;
      color: #FFA008;
      text-align: center;
      line-height: 28px;
      font-weight: bold;
      margin-bottom: 40px;
      img{
        width: 180px;
        height: 25px;
        vertical-align:top !important;
        margin-right: 15px;
      }
    }
    .carousel-container {
      position: relative;
      width: 100%;
      height: 300px;
    }
    .carousel {
      display: flex;
      overflow: hidden;
      height: 100%;
    }
    .carousel-wrapper {
      display: flex;
      width: 100%;
      justify-content:space-around;
      align-items: center;
      padding: 20px 0 0 0;
    }
    .carousel-item {
      width: 31% !important;
      height: 240px;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border-radius: 10px;
      font-size: 18px;
      padding: 0px 15px 10px;
      margin: 0 20px;
      .stars{
        img{
          width: 14px;
          height: 14px;
        }
      }
      h1{
        color: black;
        font-size: 15px;
        line-height: 18px;
        text-align:  center;
        font-weight: bold;
        margin-top: 20px;
      }
      h2{
        color: black;
        font-size: 12px;
        line-height: 18px;
        text-align:  center;
        font-weight: 500;
        margin-bottom: 10px;
      }
      img{
        width: 70px;
        height: 70px;
        margin-top: -30px;
      }
      p{
        color: #666666;
        height: 100px;
        font-size: 12px;
        text-align: left;
        font-weight: normal;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
    .carousel-button {
      // position: absolute;
      // padding: 20px;
      font-size: 20px;
      border: none;
      background-color: transparent;
      color: #555;
      outline: none;
      margin: 10px 5px;
      cursor: pointer;
      img{
        width: 30px;
      }
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.2s, transform 0.2s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
      transform: translateX(200px);
    }
  }
  .inner5{
    background: #fff;
    padding:20px calc((100% - 1200px) / 2);
    .content{
      display: flex;
      flex-direction: column;
      h1{
        text-align: center;
        margin-top: 30px;
      }
      .inner1_text{
        font-size: 13px;
        color: #333;
        line-height: 24px;
        margin: 25px 0 40px;
        padding: 0 190px;
      }
      .left_cell,.right_cell{
        flex: 1;
        // display: flex;
        // justify-content: center;
        // padding: 0 0 60px;s
      }
      .right_cell{
        img{
          margin: 25px auto !important;
          width: 380px;
          height: 380px;
          display: block;
        }
      }
    }
  }
  .inner6{
    background: #fff;
    padding:70px calc((100% - 1000px) / 2) ;
    .inner6_item{
      display: flex;
      align-items: center;
      width: 1200px;
      .inner6_item_left{
        z-index: 2;
        border-radius: 10px;
        background: #03863D;
        color: #ffffff;
        padding:35px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        width: 45%;
        h1{
          font-size: 35px;
          margin-bottom: 26px;
          width: auto;
        }
        .inner6_item_left_list{
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img{
            width: 24px;
            height: 24px;
            margin-right: 18px;
          }
        }

      }
        .inner6_item_right{
          width: 42%;
          z-index: 1;
          margin-left: -40px;
          img{
            padding: 2px;
            // border: 2px dashed rgb(206, 206, 206);
            width: 100%;
          }
        }
    }

  }
}
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;
      height: 270px;
      position: relative;
      background:white;
      .left_item{
        // margin: 0px 10px 10px;
        padding: 30px 0px 0 0 !important;
        flex: 1;
        h1{
          font-size: 25px;
          line-height: 35px;
          // line-height: 50px;
          padding-left: 30px;
        }
        h4{
          font-size: 12px;
          margin: 0 !important;
          padding-left: 30px;
        }
        & h4:nth-of-type(1){
          margin-top: 60px;
        }
        .item_download {
          margin-top: 20px;
          padding-left: 0 !important;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
        .wbg{
          // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
        }
        .item_info{
          background: #063EA4;
          padding-left: 10px;
          margin: 30px 0;
          justify-content: center;
          .info_tips{
            p{
              font-size: 12px;
            }
            img{
              width: 15px;
            }
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2{
      padding: 50px calc((100% - 1200px) / 2) 0px;
      .inner2_bg{
        background: none;
      }
      div{
        width: 100%;
        padding: 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 30px;
      }
      p{
        margin-bottom:20px;
      }
      .inner2_item{
        flex-direction: column;
      }
      .content{
        width: 100%;
        // padding: 20px 0;
        h1{
          margin: 10px 0 20px;
          font-size: 20px;
          text-align:  center !important;
        }
        p{
          margin-bottom: 10px;
          width: 85%;
        }
      }
      .text_r{
        text-align: left !important;
        p{
          text-align: left !important;
        }
      }
      .content2{
        text-align: left;
        p{
        text-align: left;
        }
      }
    }
    .inner3{
      width: calc(100vw);
      padding: 50px 20px;
      height: 13sssssssssssssssssssssssssssssss00px;
      justify-content: center;
      .left_item{
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
      .right_item{
        margin: 0;
        width: calc(100vw - 40px);
        p{
          font-size: 18px;
        }
        // img{
        //   // width: 100%;
        //   // height: 60px;
        // }
        .item_tips{
          width: calc(100vw - 40px);
          p{
            font-size: 12px !important;
            width: 70px;
          }
        }
        .item_info{
          .info_tips{
            height: auto;
          }
        }
      }
    }
    .inner4{
      padding: 30px 20px 30px;
      position: relative;
      display: none;
      h3{
        text-align: center;
        margin-bottom: 30px;
        line-height: 30px;
      }
      .carousel-container{
        height: 320px;
      }
      .carousel-item{
        min-width: 150px !important;
        width: 45% !important;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        h1{
          font-size: 12px;
          padding: 0 !important;
        }
        img{
          margin-bottom: 10px;
        }
        p{
          display: none;
        }
      }
      .carousel-button{
        position: absolute;
      }
    }
    .inner6{
      padding: 30px 20px 30px;
      h3{
        text-align: center;
        margin-bottom: 30px;
      }
      .inner6_item{
        width: 100%;
        flex-direction: column;
        .inner6_item_left{
          width: calc(100% - 40px) !important;
          padding: 20px 0;
          div{
            padding: 0 30px;
          }
          .inner6_item_left_title{
            font-size: 20px !important;
            text-align: center;
          }
          .inner6_item_left_list{
            justify-content: left;
            img{
              width: 17px;
              height: 17px;
            }
            span{
              font-size: 12px;
            }
          }
        }
        .inner6_item_right{
          width: calc(100% - 80px) !important;
          margin: -20px auto 0;
          z-index: 2;
        }
      }
    }
  .inner5{
    h1{
      margin-top: 30px !important;
    }
    h4{
      padding: 0 20px;
    }
    .inner1_text{
      padding: 0 !important;
    }
    .content{
      padding: 0 20px !important;
      display: flex;
      flex-direction: column;
      .left_cell{
        padding: 0;
      }
      .cell_item{
        width: 100% !important;
        height: 100px;
      }
      img{
        margin-top: 0px;
        width: 300px !important;
        height: 300px !important;
        margin: 0 auto ;
      }
    }
  }
  }
}
</style>
